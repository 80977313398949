<template>
  <div 
    class="recommend-v2__list mgds-recommend mgds-goodsd"
    :da-expose-code="componentsCode"
    :class="{
      'mgds-goodsd__has-more': showViewMoreIcon && !status.loadError,
      'mgds-goodsd__is-new': config.isNew,
      'mgds-goodsd__is-new-three': config.isNew && rowNum === 3,
      'holder': showHolder
    }"
  >
    <!-- holder S -->
    <template v-if="showHolder">
      <!-- 新商卡双列场景 -->
      <RecommendGoodsHolderTowRow
        v-if="rowNum === 2"
        newCard
      />
      <div
        v-else
        class="recommend-goods-holder" 
      >
        <div
          v-for="i in (rowNum * 8)" 
          :key="i" 
          :class="{
            'recommend-goods-holder__container-three': rowNum === 3,
            'recommend-goods-holder__container': rowNum === 2,
            'recommend-goods-holder__optimize': isPlaceholderOptimize && rowNum === 2,
            'recommend-goods-holder__optimize-three': isPlaceholderOptimize && rowNum === 3,
          }"
        >
          <div 
            :class="{
              'recommend-goods-holder__placehold': rowNum === 2,
              'recommend-goods-holder__placehold-three': rowNum === 3,
            }"
          >
          </div>
          <div
            v-if="isPlaceholderOptimize"
            class="recommend-goods-holder__bottom-info_one"
          ></div>
          <div
            v-if="isPlaceholderOptimize"
            class="recommend-goods-holder__bottom-info_two"
          ></div>
        </div>
      </div>
    </template>
    <!-- holder E -->
    <template v-if="config.waterFallStyle">
      <WaterFall
        :items="products"
        :class="{
          'water-fall__new': config.isNew
        }"
      >
        <template
          slot="default"
          slot-scope="{ item, index: itemIndex }"
        >
          <SProductItem
            v-if="!config.isNew && products.length && (itemIndex + 1 <= maxLimitNum)"
            :key="`${itemIndex}_${item.goods_id}`"
            :ref="`goodsItem_${item.goods_id}`"
            :language="itemLanguage"
            :constant-data="constantData"
            :item="item"
            :index="itemIndex"
            :config="config.items || {}"
            :column="rowNum"
            :cur-operate-index="feedbackIndex"
            class="recommend__goods-item"
            :class="{
              'recommend__goods-item-two': rowNum === 2,
              'recommend__goods-item-new': config.isNew,
              'recommend__goods_item-scroll': config.scrollRecItemIndex && (itemIndex === config.scrollRecItemIndex),
              'recommend__goods_item-heightLight': heightLight && config.scrollRecItemIndex && (itemIndex === config.scrollRecItemIndex)
            }"
            @longClick="handleFeedbackIndex"
            @hook:mounted="handleItemMounted"
            @clickItem="clickItem"
            @openQuickAdd="openQuickAdd"
            v-on="$listeners"
          />
          <SProductItemV3
            v-if="config.isNew"
            :key="`${itemIndex}_${item.goods_id}`"
            :ref="`goodsItem_${item.goods_id}`"
            :language="itemLanguage"
            :constant-data="constantData"
            :item="item"
            :index="itemIndex"
            :config="config.items || {}"
            :column="rowNum"
            :cur-operate-index="feedbackIndex"
            class="recommend__goods-item"
            :class="{
              'recommend__goods-item-new': true,
              'recommend__goods_item-scroll': config.scrollRecItemIndex && (itemIndex === config.scrollRecItemIndex),
              'recommend__goods_item-heightLight': heightLight && config.scrollRecItemIndex && (itemIndex === config.scrollRecItemIndex)
            }"
            @longClick="handleFeedbackIndex"
            @hook:mounted="handleItemMounted"
            @clickItem="clickItem"
            @openQuickAdd="openQuickAdd"
            v-on="$listeners"
          />
        </template>
      </WaterFall>
    </template>
    <!-- list S -->
    <template v-else>
      <template v-for="(item, key) in products">
        <SProductItem
          v-if="!config.isNew && (key + 1 <= maxLimitNum)"
          :key="`${key}_${item.goods_id}`"
          ref="goodsItem"
          :language="itemLanguage"
          :constant-data="constantData"
          :item="item"
          :index="key"
          :config="config.items || {}"
          :column="rowNum"
          :cur-operate-index="feedbackIndex"
          class="recommend__goods-item"
          :class="{
            'recommend__goods-item-three': rowNum === 3,
            'recommend__goods-item-two': rowNum === 2,
            'recommend__goods_item-scroll': config.scrollRecItemIndex && (key === config.scrollRecItemIndex),
            'recommend__goods_item-heightLight': heightLight && config.scrollRecItemIndex && (key === config.scrollRecItemIndex)
          }"
          @longClick="handleFeedbackIndex"
          @hook:mounted="handleItemMounted"
          @clickItem="clickItem"
          @openQuickAdd="openQuickAdd"
          v-on="$listeners"
        />
        <SProductItemV3
          v-if="config.isNew"
          :key="`${item.goods_id}_${key}`"
          ref="goodsItem"
          :language="itemLanguage"
          :constant-data="constantData"
          :item="item"
          :index="key"
          :config="config.items || {}"
          :column="rowNum"
          :cur-operate-index="feedbackIndex"
          class="recommend__goods-item"
          :class="{
            'recommend__goods-item-three': rowNum === 3,
            'recommend__goods-item-new': true,
            'recommend__goods_item-scroll': config.scrollRecItemIndex && (key === config.scrollRecItemIndex),
            'recommend__goods_item-heightLight': heightLight && config.scrollRecItemIndex && (key === config.scrollRecItemIndex)
          }"
          @hook:mounted="handleItemMounted"
          @longClick="handleFeedbackIndex"
          @clickItem="clickItem"
          @openQuickAdd="openQuickAdd"
          v-on="$listeners"
        />
      </template>
    </template>
    <!-- </div> -->

    <!-- list E -->
    <!-- 首次请求失败、重新请求 -->
    <ErrorPage
      v-if="!placeholderStatus && !products.length && isPlaceholderOptimize"
      :language="language"
      @reRequest="reRequest"
    />
    <!-- list 空状态 S -->
    <SFeedback
      v-if="!placeholderStatus && !products.length && !isPlaceholderOptimize"
      class="recommend-empty"
      :content="language.SHEIN_KEY_PWA_15819"
    />
    <!-- list 空状态 E -->

    <!-- view more S -->
    <div 
      v-if="showViewMoreIcon"
      class="recommend__show-more"
      :class="{
        'recommend__more-error': status.loadError
      }"
    >
      <p 
        v-if="status.loadError"
        data-error="1"
        :da-event-click="analysis.viewMoreItem && analysis.viewMoreItem.itemDAEventClickId"
        :da-event-expose="analysis.viewMoreItem && analysis.viewMoreItem.errorDAEventExposeId"
        @click="onViewMoreClick"
      >
        {{ language.SHEIN_KEY_PWA_18764 }}
      </p>
      <button
        v-else
        :da-event-click="analysis.viewMoreItem && analysis.viewMoreItem.itemDAEventClickId"
        :data-next="pageNum + 1"
        :class="{ 'button-preload': config.viewMorePreLoad }"
        @click="onViewMoreClick"
      >
        {{ language.SHEIN_KEY_PWA_15698 }}
        <i class="iconfont icon-Product_list_downx"></i>
      </button>
    </div>
    <!-- view more E -->
    
    <!-- loading S -->
    <s-loading 
      v-if="loadingMore"
      :style="{
        width: '100%',
      }"
      :show="true"
    />
    <!-- loading E -->
  </div>
</template>

<script>
import Vue from 'vue'
import { Feedback } from '@shein/sui-mobile'
import RecommendGoodsHolderTowRow from './RecommendGoodsHolderTowRow.vue'
import SProductItem from 'public/src/pages/components/product/item_v2/Item'
import SProductItemV3 from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'

import WaterFall from 'public/src/pages/components/product/WaterFall'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import useItemMixin from './use-item-mixin'
const daEventExpose = daEventCenter.getExposeInstance()
import ErrorPage from './ErrorPage.vue'
const { language } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

Vue.use(Feedback)

export default {
  name: 'RecommendList',
  components: {
    WaterFall,
    ErrorPage,
    RecommendGoodsHolderTowRow,
    // ProductItem,
    SProductItem,
    SProductItemV3
  },
  mixins: [useItemMixin],
  inheritAttrs: false,
  props: {
    // 当前数据类型
    dataType: {
      type: String,
      default: ''
    },
    // 当前数据是不是容错的数据
    isTolerant: {
      type: Boolean,
      default: false
    },

    products: {
      type: Array,
      require: true,
      default: () => []
    },
    rowNum: {
      type: [String, Number],
      default: 2
    },
    // 占位图（骨架图）展示状态
    placeholderStatus: {
      type: Boolean,
      default: false
    },
    // 是否是优化的占位图（骨架图）
    isPlaceholderOptimize: {
      type: Boolean,
      default: false
    },
    // 是否是最后一页码
    isEnd: {
      type: Boolean,
      default: false
    },
    
    /**
     * @param {Boolean} showViewMore 展示加载按钮
     * @param {Boolean} viewMorePreLoad 是否需要预加载
     * @param {Number} viewMorePosition viewmore展示在第几页
     */
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    analysis: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 商品的请求配置
    request: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      language,
      pageNum: 1,         // 第几页
      totalNum: 100,      // 总共要求展示多少个？
      paginalSize: 100,   // 每页展示请求数量是多少
      loadingMore: false,
      viewPointObserve: false,
      status: {
        loadError: false,
      },
      waterFallStyle: false, // 是否一行两列&&需要瀑布流显示
      recoLoadmoreAbt: {}, // RecoLoadmore
      heightLight: true, // 是否开启高亮 仅首次进入页面开启
      isScrollEnd: false, 
      feedbackIndex: -1, // 负反馈用
    }
  },
  computed: {
    // 当前实际返回的商品总数 A
    // 当前需要请求的总数 Q
    // A < Q 再判断是否是已经没数据了
    // A = Q 
    // A > Q
    showViewMoreIcon() {
      // 容错数据没有分页
      if (this.isTolerant) return false

      const curNum = this.pageNum * this.paginalSize
      // 当前大于或者等于想要的总数
      if (curNum >= this.totalNum) {
        return false
      }
      // !正在加载更多 && 配置展示view more && 当前列表有商品 && !最后一页
      return !this.loadingMore && this.config.showViewMore && this.products.length && !this.isEnd
    },
    // 最多展示多少个商品数量
    maxLimitNum() {
      // 容错数据没有分页直接返回商品总数
      if(this.isTolerant) {
        return this.totalNum
      }
      const curNum = this.pageNum * this.paginalSize
      return Math.min(curNum, this.totalNum)
    },
    componentsCode() {
      return `auto_rec_${ new Date().getTime() }`
    },
    showHolder() {
      return this.placeholderStatus && !this.products.length
    }
  },
  watch: {
    isTolerant: {
      handler(val) {
        if (val) {
          const { totalNum } = this.$options.data()
          this.paginalSize = totalNum
        }
      },
      immediate: true,
    },
    products: {
      handler(val) {
        if (!val.length) return
        // 声明式曝光埋点
        const time = setTimeout(() => {
          clearTimeout(time)
          // 恢复高亮
          this.heightLight = true
          if (this.config.waterFallStyle) {
            val.forEach(_ => {
              const goodsId = _.goods_id
              const itemInstance = this.$refs?.[`goodsItem_${goodsId}`]
              itemInstance?.setElAttr()
            })
          } else {
            const goodsItems = this.$refs?.goodsItem
            if (Array.isArray(goodsItems)) {
              goodsItems.forEach((goodsItem) => {
                goodsItem.setElAttr()
              })
            } else {
              goodsItems?.setElAttr?.()
            }
          }
          const { itemDAEventExposeId } = this.config?.items || {}
          if (itemDAEventExposeId && daEventExpose) {
            setTimeout(() => {
              daEventExpose.subscribe({
                keycode: `${this.componentsCode}\`${itemDAEventExposeId}`,
                type: 'list',  // 当前code是属于列表类型的吗
                hooks: {
                  afterExpose: ({ targets }) => {
                    // 定位到需要高亮的商品
                    this.config.scrollRecItemIndex && !this.isScrollEnd && this.scrollToTarget('.recommend__goods_item-scroll')
                    this.isScrollEnd = true

                    this.config?.getExposeTarget && typeof this.config?.getExposeTarget == 'function' && this.config?.getExposeTarget?.({ targets }) // 返回曝光元素
                  }
                }
              })
            }, 200)
          }
        })
      },
      immediate: true
    },
    showViewMoreIcon: {
      handler(val) {
        if (val) {
          /**
           * 配置了view more，且要求预加载（viewMorePreLoad）
           * 则初始化view more监听预加载时机
           */
          if (this.config.viewMorePreLoad && !this.viewPointObserve) {
            this.initViewMoreObserve()
          }
        }
      },
      immediate: true
    },
    loadingMore(val) {
      !val && this.handleLoadingEnd()
    },
    'config.scrollRecItemIndex': {
      handler() {
        // 配置变更恢复高亮
        this.heightLight = true
      }
    }
  },
  async mounted() {
    const { query = {} } = this.request || {}
    const { limit = 100, reqNum = 100 } = query
    this.totalNum = reqNum
    this.paginalSize = limit
    appEventCenter.$on('recommendProductItemAnalysisReset', () => {
      this.resetAnalysis()
      this.isScrollEnd = false
    })
  },
  activated() {
    this.resetAnalysis()
  },
  beforeDestroy() {
    this.viewPointObserve?.destroy?.()
    daEventExpose?.remove?.(this.componentsCode)
    appEventCenter.$off('recommendProductItemAnalysisReset')
  },
  methods: {
    // 处理多个负反馈同时出现问题
    handleFeedbackIndex({ index, isShow }) {
      if(!isShow) {
        return 
      }
      this.feedbackIndex = index
    },

    /**
     * 处理当loading结束的时候
     */
    handleLoadingEnd() {
      // 1. 对于有预加载的逻辑
      // 2. 请求没有出错
      if (this.viewPointObserve && !this.status.loadError) {
        /**
         * 如果：
         * 1. 请求没有出错
         * 2. 配置了view more 的位置，且view more的位置跟当前页不一样，
         * 则需要进行预加载更新监听（更新元素进行位置监听曝光）
         * 更新：经过v-if判断之后在页面上相当于重新渲染了一个新的元素，对于一个新的元素进行重新获取进行曝光监听
         */
        if (
          !this.config.viewMorePosition || 
          this.config.viewMorePosition > 1 && this.pageNum != this.config.viewMorePosition
        ) {
          this.$nextTick(() => {
            // view more 按钮经过v-if的是非之后会导致无法监听所以再等到展示的时候重新update订阅观察
            this.viewPointObserve.update(`recommendLoadMoreRef${this.componentsCode}`)
          })
        }
      }
      // 2. 加载报错需要曝光
      if (this.status.loadError) {
        this.$nextTick(() => {
          const { errorDAEventExposeId } = this.analysis?.viewMoreItem || {}
          errorDAEventExposeId && daEventExpose && daEventExpose.subscribe({
            keycode: `${this.componentsCode}\`${errorDAEventExposeId}`,
          })
        })
      }

    },

    /**
     * 添加预加载功能
     */
    initViewMoreObserve() {
      if (this.dataType == 'emarsys') return
      if (!this.viewPointObserve) {
        this.$nextTick(async () => {
          const { default: Scroll } = await import('public/src/services/expose/index.js')
          // 距离适口window.innerHeight px的时候触发loadmore
          this.viewPointObserve = new Scroll({ 
            mask: [0, 0, `-${window.innerHeight * 3}px`], 
            delay: 100 
          })
          this.viewPointObserve.observe({
            once: false,
            elements: { 
              code: `recommendLoadMoreRef${this.componentsCode}`, 
              target: `.recommend-v2__list[da-expose-code=${this.componentsCode}] .recommend__show-more`
              // this.$refs['productLoadMore']
            },
          }, () => {
            // 提前预加载商品
            this.onViewMoreClick()
          })
        })
      }
    },

    handleItemMounted() {
      this.$nextTick(() => {
        typeof observer !== 'undefined' && observer.observe()
      })
    },

    /**
     * 点击加载更多操作
     */
    onViewMoreClick() {
      this.pageNum++
      // emarsys 推荐是假分页，不做请求
      if (this.dataType == 'emarsys') return

      this.status.loadError = false
      this.$emit('onViewMoreClick', {
        pageNum: this.pageNum,
        loading: (bol, error) => {
          // loading 完成 && 请求出错了
          if (!bol && Boolean(error)) {
            this.status.loadError = true
            // 页码变成点击加载之前
            this.pageNum--
          }
          this.loadingMore = bol
        }
      })
    },
    /**
     * 重新请求操作
     */
    reRequest() {
      this.$emit('onReRequest', { pageNum: this.pageNum })
    },

    clickItem(payload) {
      const { openQuickAdd, disableMainimgJump } = this.config?.items || {}
      if (openQuickAdd && disableMainimgJump) {
        this.$emit('open-quick-add', payload)
        return
      }
      this.$emit('clickItem', payload)
    },

    openQuickAdd(payload) {
      this.$emit('open-quick-add', {
        ...(payload?.item || {}),
        index: payload?.index,
        target: payload?.target,
        imgRatio: payload?.imgRatio,
      })
    },
    // 重置曝光
    resetAnalysis() {
      daEventExpose.reset(this.componentsCode)
    },
    // 定位到目标元素
    scrollToTarget(target) {
      const targetDom = document.querySelector(target)
      targetDom.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      // 一秒以后移除样式
      setTimeout(() => {
        this.heightLight = false
      }, 1000)
    }
  },
}
</script>

<style lang="less" scoped>
.mgds-goodsd {
  padding: 0 .32rem;
  background-color: #fff;

  &.holder {
    padding-left: 0;
    padding-right: 0;
  }

  .goodsl-wrap {
    padding-bottom: .27rem;
    position: relative;
    .flexbox();
    flex-flow: row wrap;
    .loader-data {
      width: 100%;
      text-align: center;
      margin: .27rem 0;
    }
  }
  .recommend-goods-holder {
    .flexbox();
    flex-flow: row wrap;
    padding-bottom: .27rem;
    margin-bottom: -1.44rem;
    &__container-three {
      height: 4.6933rem;
      .margin-r(.213rem);
      &:nth-child(3n) {
        .margin-r(0);
      }
    }
    &__container {
      .margin-r(.32rem);
      &:nth-child(2n) {
        .margin-r(0);
      }
    }
    &__bottom-info_one {
      width: 2.56rem;
      height: 0.45333rem;
      background-color: #f6f6f6;
      margin-top: 0.32rem;
    }
    &__bottom-info_two {
      width: 2rem;
      height: 0.34667rem;
      background-color: #f6f6f6;
      margin-top: 0.21333rem;
    }
    &__placehold {
      width: 4.52rem;
      height: 6.02rem;
      .margin-r(0);
      margin-bottom: 1.44rem;
      background-image: url(@LAZY_IMG_SOLID_COLOR);
      background-size: contain;
      &:nth-child(2n) {
        .margin-r(0);
      }
    }
    &__placehold-three {
      width: 2.978rem;
      height: 3.88rem;
      .margin-r(0);
      background-image: url(@LAZY_IMG_SOLID_COLOR);
      background-size: 2.978rem 3.88rem;
      background-repeat: no-repeat;
      &:nth-child(3n) {
        .margin-r(0);
      }
    }
    .recommend-goods-holder__optimize-three {
      height: 5.94667rem;
      /* stylelint-disable-next-line selector-max-specificity */
      .recommend-goods-holder__placehold-three {
        background-image: url(@LAZY_IMG_GREY);
      }
    }
    .recommend-goods-holder__optimize {
      height: 8.08rem;
      /* stylelint-disable-next-line selector-max-specificity */
      .recommend-goods-holder__placehold {
        height: 6.10667rem;
        background-image: url(@LAZY_IMG_GREY);
        margin-bottom: 0;
      }
    }
  }


  .recommend {
    &__goods-item-two {
      display: inline-block;
      .margin-r(.3rem);
      margin-bottom: 0.4267rem;
      &:nth-child(2n) {
        .margin-r(0);
      }
    }

    &__goods-item-three {
      display: inline-block;
      .margin-r(.213rem);
      margin-bottom: .32rem;
      &:nth-child(3n) {
        .margin-r(0);
      }
      .product-item__discount {
        font-size: 9px;
      }
    }
    &__goods-item-new {
      // display: inline-block;
      // padding: 0 0.12rem;
      // .margin-r(0);
      margin-bottom: .32rem;
    }
    &__goods_item-heightLight {
      background-color: #FFF8EB;
      .product-item__main {
        background-color: none;
      }
    }
    &__goods_item-scroll {
      transition: all 1s ease;
    }
    // recommend view more
    &__show-more {
      width: 100%;
      text-align: center;
      background: #fff;
      z-index: @zindex-hack;
      margin-top: -0.8533rem;
      line-height: 0.8533rem;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        .left(0);
        bottom: 100%;
        width: 100%;
        height: 1.3333rem;
        background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
        // margin-bottom: -5px;
      }
      button {
        padding: 0.2133rem 0.32rem;
        background: transparent;
        color: #222;
        text-align: center;
        .font-dpr(28px);
        border: 1px solid #222;
      }
      i {
        .font-dpr(34px);
        vertical-align: middle;
      }
    }
    &__goods-isnew {
      padding: 0 0.16rem;
      margin-right: 0;
    }
  }

  &__has-more {
    /* stylelint-disable-next-line selector-max-specificity */
    .recommend__goods-item-two:nth-child(2n+1):nth-last-child(2),
    .recommend__goods-item-two:nth-child(2n+1):nth-last-child(3),
    .recommend__goods-item-two:nth-child(2n):nth-last-child(2) {
      height: 5.02rem;
      overflow: hidden;
    }

    /* stylelint-disable-next-line selector-max-specificity */
    .recommend__goods-item-three:nth-child(3n):nth-last-child(5) ~ .recommend__goods-item-three,
    .recommend__goods-item-three:nth-child(3n):nth-last-child(4) ~ .recommend__goods-item-three,
    .recommend__goods-item-three:nth-child(3n):nth-last-child(3) ~ .recommend__goods-item-three{
      height: 3.88rem;
      overflow: hidden;
    }
  }
  &__is-new {
    width: 100%;
    padding: 0 .16rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__is-new-three {
    display: block;
    padding: 0 .32rem;
  }
}

.water-fall__new {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.recommend__goods-item {
  vertical-align: top;
  font-size: 12px;
}

.recommend-empty {
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .8rem;
}

.recommend__more-error {
  margin-top: -0.3rem;
  &::before {
    display: none;
  }
  >p {
    color: #999;
  }
}

.recommend-v2__list {
  font-size: 0;
}

// font-weight: bold;
.button-preload {
  padding: 0 !important;
  height: 1px;
  overflow: hidden;
  border: none !important;
}
</style>
